body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fb9e4f;
  color: #777777;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.divs-data {
  margin-top: 50px;
}
.divs-marker {
  margin-top: 50px;
  margin-bottom: 50px;
}
.divs-logo {
  display: inline-block;
  text-align: center;
}
.logo-img {
  width: 33%;
}
.divs-button {
  display: none;
}
h2 {
  color: #ffffff;
  font-family: Lobster,cursive;
  text-shadow: 2px 2px 4px #000000;
  font-size: 50px;
}
.type-filter {
  color: snow;
  font-weight: bold;
}
.myaddress {
  color: snow;
  opacity: 0.7;
}
.product {
  background-color: snow;
  margin-top: 25px;
  margin-bottom: 25px;
}
.product-img {
  width: inherit;
}
.product-sections {
    padding-top: 50px;
    padding-bottom: 50px;
}
.autoa {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-small {
  margin-left: 15px;
  margin-right: 15px;
}
.btn-product {
  margin-top: 15px;
}
.footer-brand {
  margin-top: 100px;
  margin-bottom: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #4b4fa7;
}
.img-brand {
  height: 33px;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .logo-img {
    width: 70%;
  }
}
.btn-outline-dark {
    color: #ffffff !important;
    background-color: #92b7ef !important;
    background-image: none;
    border-color: transparent !important;
}
